import hillo from 'hillo'
import { baseUrl, getRealADI } from '@/model/storage/index'

const name = 'inventoryRecord/'

export async function getClearList () {
  return (await hillo.jsonPost(baseUrl + name + 'getClearingsList', {}, await getRealADI())).data
}

export async function getClearingSummary (dateTimeFrom = null, dateTimeTo = null, operation = null) {
  return (await hillo.jsonPost(baseUrl + 'resourceLog/' + 'getSummaries', {
      dateTimeFrom,
      dateTimeTo,
      operation,
    }, await getRealADI())).data
}

export async function getResourceCount (clearingId) {
  return (await hillo.jsonPost(baseUrl + name + 'getDishResourceAmount/' + clearingId, {}, await getRealADI())).data
}

export async function getStatistic (dateTimeFrom = null, dateTimeTo = null) {
  return (await hillo.jsonPost(baseUrl + 'statistics/' + 'getDetail', {
      dateTimeFrom,
      dateTimeTo,
    }, await getRealADI())).data
}

export async function getInOutLog (dateTimeFrom = null, dateTimeTo = null) {
  return (await hillo.jsonPost(baseUrl + 'resourceLog/' + 'getDetailList', {
    dateTimeFrom,
    dateTimeTo,
  }, await getRealADI())).data
}
